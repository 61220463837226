import botSafeSVG from '../assets/fresh_botsafe.svg';
import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import videoUrl from '../assets/automation-video.mp4';
import videoLowResUrl from '../assets/automation-video_360.mp4';

const SplashContainer = styled.div`
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
`;

const VideoBackground = styled.video`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translateX(-50%) translateY(-50%);
    z-index: -1;
    filter: brightness(50%);
    object-fit: cover;
    
    border: none;
    outline: none;
`;

const Content = styled.div`
    text-align: center;
    color: white;
    width: 90%;
    max-width: 1200px;
    z-index: 1;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const LogoContainer = styled.div`
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 4rem;
    width: 25%;
    max-width: 256px;
    min-width: 100px;
`;

const LogoImage = styled.img`
    width: 100%;
    height: auto;
    display: block;
    filter: drop-shadow(0 0 10px white);
    animation: glow 3s infinite alternate;

    @keyframes glow {
        0% {
            filter: drop-shadow(0 0 2px white);
        }
        50% {
            filter: drop-shadow(0 0 6px white);
        }
        100% {
            filter: drop-shadow(0 0 2px white);
        }
    }
`;

const TextContent = styled.div`
    margin-top: 1rem;
`;

// const BotSafe = styled.p`
//     font-size: 3rem;
//     font-weight: 800;
//     color: rgba(255, 255, 255, 1);
// `;

const Title = styled.p`
    font-size: 1.25rem;
    font-weight: 400;
    color: rgba(231, 231, 231, 0.68);
    margin-top:    0rem;
    margin-bottom: 6rem;
`;

const Subtitle = styled.h3`
    font-size: 1.5rem;
    margin-top: 2rem;
    color: #eee;
    text-shadow: 3px 3px 3px rgba(0,0,0,0.3);
`;

const Header = styled.h1`
    font-size: 2.5rem;
    margin-top: 4rem;
    color: #eee;
    text-shadow: 3px 3px 3px rgba(0,0,0,0.3);
`;

const SplashScreen = () => {
    const [isHighResLoaded, setIsHighResLoaded] = useState(false);
    const highResVideoRef = useRef(null);

    useEffect(() => {
        const handleCanPlay = () => {
            setIsHighResLoaded(true);
        };

        const highResVideo = highResVideoRef.current;
        if (highResVideo) {
            highResVideo.addEventListener('canplay', handleCanPlay);
            return () => {
                highResVideo.removeEventListener('canplay', handleCanPlay);
            };
        }
    }, []);

    return (
        <SplashContainer>
            {/* Low Resolution Video */}
            <VideoBackground
                autoPlay
                loop
                muted
                playsInline
                src={videoLowResUrl}
                style={{ opacity: isHighResLoaded ? 0 : 1 }}
            />
            {/* High Resolution Video */}
            <VideoBackground
                ref={highResVideoRef}
                autoPlay
                loop
                muted
                playsInline
                src={videoUrl}
                style={{ opacity: isHighResLoaded ? 1 : 0 }}
            />
            <Content>
                <LogoContainer>
                    <LogoImage src={botSafeSVG} alt="BotSafe"/>
                </LogoContainer>
                <TextContent>
                    {/*<BotSafe>BotSafe</BotSafe>*/}
                    <Title>BotSafe — The Safe OSRS Bot</Title>
                    <Header>Say Goodbye to Bans</Header>
                    <Subtitle>Embrace the Future of Smart and Safe OSRS Botting with AI</Subtitle>
                </TextContent>
            </Content>
        </SplashContainer>
    );
};

export default SplashScreen;
